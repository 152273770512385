import styles from '../Details.module.scss';

import PropTypes from 'prop-types';
import { useCurrencyInfo } from 'utils/hooks';
import { UseSanaTexts } from 'components/sanaText';
import { Select } from 'components/primitives/form';
import { formatAsPercentage, formatAsPrice } from 'utils/format';

const AgreementLinesDropdown = ({ agreementLines, selectedLineId, canViewUom, onChange }) => {
  const currencyInfo = useCurrencyInfo();

  const textKeys = [
    'NotUseSalesAgreement',
    'Id',
    'Discount',
    'Price',
    'Remaining',
    'UnitOfMeasure',
    'LocationTitle',
  ];

  return (
    <UseSanaTexts options={textKeys}>
      {texts => (
        <div className={styles.agreementDropdown}>
          <Select
            id="agreement_selector_dropdown"
            className={styles.agreement}
            optionClassName={styles.agreementOption}
            items={createItems(agreementLines, texts, currencyInfo, canViewUom)}
            onChange={onChange}
            value={selectedLineId}
          />
        </div>
      )}
    </UseSanaTexts>
  );
};

AgreementLinesDropdown.propTypes = {
  agreementLines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      discountPercent: PropTypes.number,
      price: PropTypes.number,
      uom: PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
      }),
      quantities: PropTypes.shape({
        remaining: PropTypes.number,
        commitment: PropTypes.number,
      }).isRequired,
      amounts: PropTypes.shape({
        remaining: PropTypes.number,
      }).isRequired,
      location: PropTypes.shape({
        code: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  ),
  selectedLineId: PropTypes.string,
  canViewUom: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AgreementLinesDropdown;

const createItems = (lines, texts, currencyInfo, canViewUom) => {
  return [{
    value: null,
    name: texts[0],
  },
  ...lines.map(line => ({
    value: line.id,
    name: createTitle(line, texts, currencyInfo, canViewUom),
  })),
  ];
};

const createTitle = (line, texts, currencyInfo, canViewUom) => {
  let title = `${texts[1]}: ${line.id}`;

  if (exists(line.discountPercent))
    title += ` ${texts[2]}: ${toPercentage(line.discountPercent, currencyInfo)}`;

  if (exists(line.price))
    title += ` ${texts[3]}: ${toPrice(line.price, currencyInfo)}`;

  if (exists(line.quantities.remaining))
    title += ` ${texts[4]}: ${line.quantities.remaining} ${canViewUom && line.uom ? line.uom.description || line.uom.id : ''}`;
  else if (exists(line.amounts.remaining))
    title += ` ${texts[4]}: ${toPrice(line.amounts.remaining, currencyInfo)}`;

  if (canViewUom && line.uom && line.uom.id && !exists(line.quantities.commitment))
    title += ` ${texts[5]}: ${line.uom.description || line.uom.id}`;

  if (line.location)
    title += ` ${texts[6]}: ${line.location.title || line.location.code}`;

  return title;
};

const exists = value => value !== null && value !== undefined;

const toPercentage = (discount, currencyInfo) => formatAsPercentage(discount, currencyInfo, true, 2);

const toPrice = (price, currencyInfo) => formatAsPrice(price, currencyInfo);
