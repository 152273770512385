import styles from '../product/Details.module.scss';
import { memo } from 'react';
import ProductProvider from './ProductProvider';
import { Col, Container, Row } from 'components/primitives/grid';
import ProductTitle from './ProductTitle';
import ProductImage from './ProductImage';
import OrderBox from '../product/OrderBox';

//3.3 UOM Calculation
const Details = () => {

  return (
    <ProductProvider>
      <Container>
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 2 }} md={4}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            <ProductImage />
          </Col>

          <Col xs={{ size: 12, order: 1 }} md={8}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <ProductTitle />
            <OrderBox isPopup={true} />

          </Col>
        </Row>
      </Container>
    </ProductProvider>
  );
};

export default memo(Details);
