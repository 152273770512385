import { addProducts } from 'behavior/basket';
import ProductSampleStyles from './ProductSample.module.scss';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { SanaButton } from 'components/primitives/buttons';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { Col, Row } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { ProductStock } from 'components/primitives/product';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generateKey } from 'utils/helpers';
import styles from '../Details.module.scss';
import SamplesContext from './SamplesContext';


//3.4.Sample ordering
const SampleLines = ({ modifiedDate, updatedById, addProducts }) => {

  const { product, variantId, isPopup, getSamples } = useContext(SamplesContext);
  if (!product)
    return (<></>);

  const [componentId] = useState(generateKey);
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (updatedById === componentId)
      setDisabled(false);
  }, [modifiedDate, updatedById]);

  const {
    uomId,
    stockLevels, } = product;

  const samples = getSamples(product, variantId);

  const addToBasket = useHandlerLockerInPreview(useCallback(e => {
    e.preventDefault();

    const warehouseId = e.target.offsetParent.getAttribute("data-warehouseid");
    const warehouseName = e.target.offsetParent.getAttribute("data-warehousename");

    if (disabled || !warehouseId || !warehouseName)
      return;

    setDisabled(true);

    const line = {
      productId: product.id,
      variantId,
      uomId,
      quantity: 1,
      salesAgreementLineId: null,
      warehouseId,
      warehouseName,
    };

    addProducts([line], componentId, null, samples, isPopup);

  }, [product, uomId, variantId, disabled]));

  if (!samples)
    return (<></>);

  return (

    samples.map(sample => {
      return (
        <div key={sample.warehouseId} className={`${ProductSampleStyles.popupWrapper}`}>
          <Row key={sample.warehouseId} className={styles.row} crossAxisAlign="center">
            <Col xs={12} lg={4}>
              <h4>{sample.warehouseName}</h4>
            </Col>
            <Col xs={12} lg={4}>
              <h4> <ProductStock inventory={sample.inventory} stockLevels={stockLevels} /></h4>
            </Col>
            <Col xs={12} lg={4}>
              {sample.inventory > 0 && <div  className={styles.limitedWidth}>
                <SanaButton
                  textKey="AddToBasket"
                  type="button"
                  className={`${btnStyles.btnMedium} ${styles.addToBasketBtn}`}
                  placeholder={btnPlaceholder}
                  onClick={addToBasket}
                  disabled={disabled}
                  id={sample.warehouseId}
                  data-warehouseid={sample.warehouseId}
                  data-warehousename={sample.warehouseName}
                />
              </div>}
            </Col>
          </Row>
        </div>
      );
    })
  );
};

SampleLines.propTypes = {
  addProducts: PropTypes.func.isRequired,
  modifiedDate: PropTypes.number,
  updatedById: PropTypes.string,
};

export default connect(({ basket }) => ({
  modifiedDate: basket.modifiedDate,
  updatedById: basket.updated.updaterId,
}), { addProducts })(SampleLines);