exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSample_tool-tip-button{margin-right:10px}@media (min-width:var(--breakpoints_Small,600)px){.ProductSample_popup-wrapper h4{margin:20px 0}}@media (max-width:var(--breakpoints_Small,600)px){.ProductSample_popup-wrapper h4{margin:5px 0}.ProductSample_popup-wrapper button{margin-top:5px}}", ""]);

// exports
exports.locals = {
	"tool-tip-button": "ProductSample_tool-tip-button",
	"toolTipButton": "ProductSample_tool-tip-button",
	"popup-wrapper": "ProductSample_popup-wrapper",
	"popupWrapper": "ProductSample_popup-wrapper"
};