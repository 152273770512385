import { abilitiesPropType } from 'behavior/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { ConfigureButton } from 'components/objects/productConfigurator';
import { withAbilities } from 'components/objects/user';
import { SanaButton } from 'components/primitives/buttons';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { Col, Row } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { OfflinePriceWarning, PricePer, ProductPrice, ProductStock, UomSelector, useCanViewUom } from 'components/primitives/product';
import { RichText, SimpleText } from 'components/sanaText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { usePrintMode } from 'utils/hooks';
import { BomComponents } from './bomComponents';
import styles from './Details.module.scss';
import { useProductContext } from './hooks';
import OrderBoxForm from './OrderBoxForm';
import QuantityBox from './QuantityBox';
import { AgreementSelector } from './salesAgreements';
import { showMatrix } from './showMatrix';
import { VariantDropdowns } from './variantDropdowns';
import { VariantsMatrixPopup } from './variantsMatrix';
import { VolumePricesButton } from './volumePrices';
import { AddToWishlist } from './wishlist';
import Spinner from 'components/primitives/spinner/Spinner';

//3.3.UOM Calculator
import MetersSquaredPerBox from '../productPopup/quantityPriceCalculation/MetersSquaredPerBox';
import QuantityPriceCalculation from '../productPopup/quantityPriceCalculation/QuantityPriceCalculation';
import OtherUomDescriptor from '../productPopup/quantityPriceCalculation/OtherUomDescriptor';

//3.4 Sample ordering
import AddSampleButton from './productSamples/AddSampleButton';


const OrderBox = ({
  matrixPreset,
  abilities: [orderProductsAbility, useSalesAgreementsAbility],
  wishListEnabled,
  allowUOMSelection,
  salesAgreement,
  isPopup,
}) => {
  const { product, variantId, calculatedInfo, pricesInclTax, updateUomId, uomId: selectedUomId } = useProductContext();
  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();

  if (!product)
    return null;

  const { price, listPrice, inventory, isOrderable: selectedVariantIsOrderable } = calculatedInfo;

  if (isPopup && !(price >= 0)) {
    return (<h3><Spinner /></h3>);
  }

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    productConfiguratorInfo,
    hasVolumePrices,
    metersSquaredPerBox,
    quantityPriceCalculationRequired,
    canBeSoldAsSample,
  } = product;

  const uomId = canViewUom && product.uom && product.uom.id;
  const canOrderProducts = orderProductsAbility === AbilityState.Available;

  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  const taxSuffixPlaceholder = <Placeholder className={styles.taxSuffixPlaceholder} />;
  const availabilityTextPlaceholder = <Placeholder className={styles.availabilityTextPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const priceRowPlaceholder = <Placeholder className={styles.priceRowPlaceholder} />;
  const availabilityPlaceholder = <Placeholder className={styles.availabilityPlaceholder} />;

  if (matrixPreset && product.hasVariants) {
    const matrixSupported = product.variantComponentGroups?.length <= 2;
    if (isPrintMode && matrixSupported)
      return null;

    const calculationInfoIsLoaded = product.variants.some(v => v.isOrderable !== undefined);

    if (matrixSupported && !calculationInfoIsLoaded && productOrderable)
      return btnPlaceholder;

    if (productOrderable && showMatrix(product)) {
      const showBomComponents = !!product.bomComponents?.length;
      return (
        <>
          <VariantsMatrixPopup />
          {showBomComponents && <BomComponents />}
          {hasVolumePrices && <VolumePricesButton showCompleteList canOrderProducts={canOrderProducts} />}
        </>
      );
    }
  }

  const shouldShowOrderBox = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && selectedVariantIsOrderable && productOrderable)
    || (canOrderProducts && selectedVariantIsOrderable === false);

  /*Ticket 200621: M2 per box*/
  let uomDescriptor = null;
  if (canBeSoldAsSample) {
    uomDescriptor = (
      <MetersSquaredPerBox metersSquaredPerBox={metersSquaredPerBox} />
    );
  } else {
    uomDescriptor = (
      <OtherUomDescriptor metersSquaredPerBox={metersSquaredPerBox} />
    );
  }

  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);
  let orderBox = null;
  if (!isPrintMode && shouldShowOrderBox) {
    if (canOrderProducts) {

      {/*3.3.UOM Calculator*/ }
      if (metersSquaredPerBox && quantityPriceCalculationRequired) {
        orderBox = (
          <QuantityPriceCalculation metersSquaredPerBox={metersSquaredPerBox} product={product} />
        );
      }
      else {

        const canOrderProduct = selectedVariantIsOrderable && productOrderable;
        const shouldShowAgreementSelector = productOrderable
          && useSalesAgreementsAbility === AbilityState.Available
          && salesAgreement;

        orderBox = (
          <OrderBoxForm>
            {shouldShowAgreementSelector &&
              <AgreementSelector
                allowUomSelection={allowUOMSelection}
                canViewUom={canViewUom}
                salesAgreement={salesAgreement}
              >
                {(label, control) => (
                  <Row className={styles.row} crossAxisAlign="center">
                    <Col xs={3} lg={2} className={styles.ellipsis} id="agreement_selector_Label">
                      {label}
                    </Col>
                    <Col xs={9} lg={10} aria-labelledby="agreement_selector_Label">
                      {control}
                    </Col>
                  </Row>
                )}
              </AgreementSelector>
            }
            <QuantityBox disabled={!canOrderProduct} />
            {canOrderProduct &&
              <Row className={styles.row} crossAxisAlign="center">
                <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
                  {productConfiguratorInfo.isProductConfigurable
                    ? <ConfigureButton />
                    : (
                      <SanaButton
                        textKey="AddToBasket"
                        type="submit"
                        className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.addToBasketBtn}`}
                        placeholder={btnPlaceholder}
                      />
                    )
                  }
                </Col>
                <Col xs={12} className={styles.gutter} />
                <Col xs={12} sm="auto" className={styles.limitedWidth}>
                  {showAddToWishList &&
                    <div className={styles.afterBtnLinks}>
                      <AddToWishlist />
                    </div>
                  }
                </Col>
              </Row>
            }
          </OrderBoxForm>
        );

      }
    }
    else if (uomId && allowUOMSelection && product.uoms?.length > 1) {
      orderBox = (
        <Row>
          <Col xs={{ size: 9, offset: 3 }} lg={{ size: 10, offset: 2 }}>
            <UomSelector
              className={styles.uom}
              productId={product.id}
              allowUOMSelection={allowUOMSelection}
              uomId={selectedUomId || uomId}
              uoms={product.uoms}
              onUomChange={updateUomId}
              isOrderable={selectedVariantIsOrderable}
            />
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
      {!isPrintMode && <BomComponents />}
      {shouldShowOrderBox &&
        <div className={styles.orderBox}>
          {price !== null && (
            typeof price !== 'undefined'
              ? (
                <Row className={styles.row} crossAxisAlign="center">
                  <Col xs={3} lg={2} className={styles.ellipsis} id="price_Label">
                    <SimpleText textKey="Price" placeholder={pricePlaceholder} />
                  </Col>
                  <Col xs={9} lg={10} className={styles.field}>
                    <Row className={styles.prices}>
                      <ProductPrice salesPrice={price} basePrice={listPrice} />
                      <Col xs={12} sm="auto" className={styles.afterPrice}>
                        {uomId && <PricePer uomId={uomId} uoms={product.uoms} />}
                        <div>
                          {pricesInclTax != null && (
                            <SimpleText
                              textKey={pricesInclTax ? 'InclTaxSuffix' : 'ExclTaxSuffix'}
                              placeholder={taxSuffixPlaceholder}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    {!isPrintMode && hasVolumePrices && <VolumePricesButton canOrderProducts={canOrderProducts} />}
                  </Col>
                  {!isPrintMode && (
                    <Col>
                      <OfflinePriceWarning />
                    </Col>
                  )}
                </Row>
              )
              : priceRowPlaceholder
          )}
          {/*3.3.UOM Calculator*/}

          {/*Ticket 200621: M2 per box*/}
          {metersSquaredPerBox && uomDescriptor }

          {inventory !== null && stockLevels != null && (
            <>
              {
                typeof inventory === 'number'
                  ? (
                    <Row className={styles.row} crossAxisAlign="center">
                      <Col xs={3} lg={2} className={styles.ellipsis} id="availability_Label">
                        <SimpleText textKey="Availability" placeholder={availabilityTextPlaceholder} />
                      </Col>
                      <Col xs={9} lg={10} aria-labelledby="availability_Label">
                        <ProductStock inventory={inventory} stockLevels={stockLevels} />
                      </Col>
                    </Row>
                  )
                  : availabilityPlaceholder
              }
            </>
          )}
          {productOrderable && hasVariants && <VariantDropdowns />}
          {/*3.4.Sample ordering*/}
          {canBeSoldAsSample && <AddSampleButton product={product} variantId={variantId} />}
          {orderBox}
          {canOrderProducts && selectedVariantIsOrderable === false && (
            <div className={styles.cannotOrder}>
              <RichText textKey={hasVariants ? 'ProductVariantCannotBeOrderedAtThisTime' : 'ProductCannotBeOrderedAtThisTime'} />
              {showAddToWishList && !isPrintMode && <>{' '}<AddToWishlist /></>}
            </div>
          )}
        </div>
      }
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  matrixPreset: PropTypes.bool,
  wishListEnabled: PropTypes.bool,
  allowUOMSelection: PropTypes.bool,
  salesAgreement: PropTypes.object,
  isPopup: PropTypes.bool,
};

const mapStateToProps = ({
  settings: {
    wishListEnabled,
    product,
  },
  page: { salesAgreement },
}) => ({
  wishListEnabled,
  allowUOMSelection: product && product.allowUOMSelection,
  salesAgreement,
});

export default withAbilities(
  connect(mapStateToProps)(OrderBox),
  [AbilityTo.OrderProducts, AbilityTo.UseSalesAgreements],
);
