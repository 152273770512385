import { toasts } from 'behavior/toasts';
import ProductSampleStyles from './ProductSample.module.scss';
import { basketResultUpdated } from 'behavior/basket';
import { Modal, useModal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { Col, Row } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TooltipLabel from '../../productPopup/quantityPriceCalculation/TooltipLabel';
import styles from '../Details.module.scss';
import Locations from './Locations';
import SamplesContext from './SamplesContext';
import { SimpleText } from 'components/sanaText';

//3.4.Sample ordering
const AddSampleButton = ({ product, variantId }) => {

  const context = {
    product,
    variantId,
    getSamples: function (product, variantId) {

      const { samples, variants } = product;
      if (variantId) {
        const variant = variants.filter((v) => {
          if (v.id === variantId)
            return v;
        });
        return variant.length ? variant[0].samples : [];
      }

      return samples;
    }
  };

  const placeholder = <Placeholder className="placeholder" />;
  const { opened, show, hide } = useModal();
  const dispatch = useDispatch();

  const showPopup = useCallback(() => {

    const samples = context.getSamples(product, variantId);
    if (samples && samples.length) {

      dispatch(basketResultUpdated([]));
      show();
    }
    else {
      toasts.error(<SimpleText textKey="SampleOrdering_SampleNotAvailable" />);
    }

  }, [variantId]);

  return (
  <div >
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
        <SanaButton className={`${btnStyles.btnSmall} ${ProductSampleStyles.toolTipButton} btn-action`}
          textKey={"AddSample"}
          onClick={showPopup}
          placeholder={placeholder} />

        <TooltipLabel  messageTextKey={'SampleOrdering_AddSample_Tooltip'} />

        <SamplesContext.Provider value={context}>
          <Modal opened={opened} hide={hide} key={product.id} size="medium" >
            <Locations />
          </Modal>
        </SamplesContext.Provider>
      </Col>
    </Row>
    </div>
  );
};

AddSampleButton.propTypes = {
  product: PropTypes.object.isRequired,
};

export default AddSampleButton;

