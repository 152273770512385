import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PriceCalStyles from './quantityPriceCalculation/QuantityPriceCalculation.module.scss';
import { Placeholder } from 'components/primitives/placeholders';
import { useCallback, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { SanaButton } from 'components/primitives/buttons';
import { Modal, useModal } from 'components/objects/modals';
import PropTypes from 'prop-types';
import { requestProductPopup } from 'behavior/pages/productPopup';
import Details from './Details';

//3.3.UOM Calculator
const PopuButton = ({ product, productPopup, requestProductPopup, quickOrderOnProductAdd }) => {

  if (!product && !productPopup)
    return (<><span>Loading...</span></>);

  const { opened, show, hide } = useModal();
  const placeholder = <Placeholder className="placeholder" />;

  const {
    id: productId,
    hasVariants,
  } = product;

  const showPopup = useCallback(() => {

    requestProductPopup(productId);
    show();

    if (quickOrderOnProductAdd) {
      quickOrderOnProductAdd();
    }

  }, []);

  const className = hasVariants ? `${btnStyles.btnSmall} btn-action` : `${btnStyles.btnAction} ${btnStyles.btnSmall} btn-action`;

  return (

    <>
      <SanaButton className={className}
        textKey={hasVariants ? 'SelectVariants' : 'AddToBasket'}
        onClick={showPopup}
        placeholder={placeholder} />

      <Modal opened={opened} hide={hide} key={productId} size="large" className={PriceCalStyles.popupWrapper} >
        <Details />
      </Modal>
    </>
  );
};

PopuButton.propTypes = {
  product: PropTypes.object.isRequired,
  onQuickOrder: PropTypes.func,
};

export default connect(
  ({ productPopup }) => ({
    productPopup: productPopup?.product,
  }), { requestProductPopup }
)(PopuButton);

