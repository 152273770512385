import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import Tooltip from 'components/primitives/tooltip';
import { makeSimpleText } from 'utils/render';

//3.3 UOM Calculation
const TooltipLabel = ({ messageTextKey }) => {
  const [message] =
    useSanaTexts([messageTextKey], makeSimpleText).texts;

  return (
    <Tooltip body={message} showOnRight>
      <span />
    </Tooltip>
  );
};

TooltipLabel.propTypes = {
  messageTextKey: PropTypes.string.isRequired,
};

export default TooltipLabel;
