import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PriceCalStyles from './QuantityPriceCalculation.module.scss';
import styles from '../Details.module.scss';
import { useProductContext } from '../../product/hooks';
import {  useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Placeholder } from 'components/primitives/placeholders';
import { SanaButton } from 'components/primitives/buttons';
import { generateKey } from 'utils/helpers';
import { AddToWishlist } from '../../product/wishlist';
import TooltipLabel from './TooltipLabel';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import { QuantityTextBox } from 'components/primitives/product';
import { createQuantityModel } from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import { addProducts } from 'behavior/basket';
import { getFormatPrice } from 'utils/format';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { bool, func, number, object, string, Date, oneOfType } from 'prop-types';

//3.3 UOM Calculation
const QuantityPriceCalculation = ({
  metersSquaredPerBox,
  culture,
  currencyInfo,
  addProducts,
  product,
  modifiedDate,
  updatedById,
  wishListEnabled,
  }) => {

  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const { uomId, variantId, productConfiguratorInfo, calculatedInfo, isPopup } = useProductContext();
  const { price } = calculatedInfo;

  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const separator = getNumberDecimalsSeparator(culture);

  const updateDesiredSurfaceQuantity = useCallback(quantity => {
    updateDesiredSurfaceContext(prev => {
      if (quantity === prev.quantity)
        return prev;

      return { ...prev, quantity };
    });
  }, []);

  const updateNumberOfBoxesQuantity = useCallback(quantity => {
    updateNumberOfBoxesContext(prev => {
      if (quantity === prev.quantity)
        return prev;

      return { ...prev, quantity };
    });
  }, []);

  const [desiredSurfaceContext, updateDesiredSurfaceContext] = useState(() => {
    const quantityModel = { ...generateQuantityModel(product, uomId), step: 0.001, minimum: 0.001 };
    return {
      uomId,
      productId: product.id,
      quantity: { value: metersSquaredPerBox, isValid: true },
      quantityModel,
      updateQuantity: updateDesiredSurfaceQuantity,
    };
  });

  const [numberOfBoxesContext, updateNumberOfBoxesContext] = useState(() => {
    const quantityModel = generateQuantityModel(product, uomId);
    return {
      uomId,
      productId: product.id,
      quantity: { value: 1, isValid: true },
      quantityModel,
      updateQuantity: updateNumberOfBoxesQuantity,
    };
  });

  const [surfaceTotal, setSurfaceTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(formatAsPrice(price * metersSquaredPerBox));

  useEffect(() => {

    updateDesiredSurfaceContext(prev => {
      if (prev.productId === product.id && prev.uomId === uomId)
        return prev;

      const quantityModel = { ...generateQuantityModel(product, uomId), step: 0.001, minimum: 0.001 };
      return {
        ...prev,
        uomId,
        productId: product.id,
        quantity: { value: quantityModel.initial, isValid: true },
        quantityModel,
      };
    });

    updateNumberOfBoxesContext(prev => {
      if (prev.productId === product.id && prev.uomId === uomId)
        return prev;

      const quantityModel = generateQuantityModel(product, uomId);
      return {
        ...prev,
        uomId,
        productId: product.id,
        quantity: { value: quantityModel.initial, isValid: true },
        quantityModel,
      };
    });

  }, [product.id, uomId, price]);

  useEffect(() => {
    setSurfaceTotal((metersSquaredPerBox * numberOfBoxesContext.quantity.value).toFixed(3));
  }, [numberOfBoxesContext.quantity, price]);

  useEffect(() => {
    updateNumberOfBoxesContext(boxes => {
      const calculatedBoxes = Math.ceil(desiredSurfaceContext.quantity.value / metersSquaredPerBox);
      setSurfaceTotal((metersSquaredPerBox * calculatedBoxes).toFixed(3));

      return {
        ...boxes,
        quantity: {
          ...boxes.quantity,
          value: calculatedBoxes,
        },
      };
    });
  }, [desiredSurfaceContext.quantity, price]);

  useEffect(() => setSubTotal(formatAsPrice(surfaceTotal * price)), [surfaceTotal, price]);

  useEffect(() => {
    if (updatedById === componentId)
      setDisabled(false);
  }, [modifiedDate, updatedById]);

  useEffect(() => {
    updateDesiredSurfaceContext(prev => {
      if (metersSquaredPerBox === prev.quantity.value)
        return prev;

      return {
        ...prev, quantity: {
          ...prev.quantity,
          value: metersSquaredPerBox,
        },
      };
    });
  }, [uomId, variantId]);

  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);

  const addToBasket = useHandlerLockerInPreview(useCallback(e => {
    e.preventDefault();

    if (surfaceTotal > 0) {

      setDisabled(true);
      const line = {
        productId: product.id,
        variantId,
        uomId,
        quantity: surfaceTotal,
        salesAgreementLineId: null,
      };
      
      addProducts([line], componentId, null, null, isPopup);
    }
  }, [product, uomId, variantId, numberOfBoxesContext, disabled, surfaceTotal]));

  return (
  <div className={PriceCalStyles.wrapper}>
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12} lg={12}>
        <h2><RichText textKey="QuantityPriceCalculation_Header" /></h2>
      </Col>
    </Row>

    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={4}>
        <SimpleText textKey="QuantityPriceCalculation_DesiredSurface" />
      </Col>
      <Col xs={9} lg={8}>
        <QuantityTextBox
          className={styles.quantity}
          quantity={desiredSurfaceContext.quantityModel}
          value={desiredSurfaceContext.quantity && desiredSurfaceContext.quantity.value}
          onChange={desiredSurfaceContext.updateQuantity}
          separator={separator}
        />
        <TooltipLabel messageTextKey={'QuantityPriceCalculation_DesiredSurface_Tooltip'} />
      </Col>
    </Row>

    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={4}>
        <SimpleText textKey="QuantityPriceCalculation_NumberOfBoxes" />
      </Col>
      <Col xs={9} lg={8}>
        <QuantityTextBox
          className={styles.quantity}
          quantity={numberOfBoxesContext.quantityModel}
          value={numberOfBoxesContext.quantity && numberOfBoxesContext.quantity.value}
          onChange={numberOfBoxesContext.updateQuantity}
          separator={separator}
        />
      </Col>
    </Row>

    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={4}>
        <SimpleText textKey="QuantityPriceCalculation_SurfaceTotal" />
      </Col>
      <Col xs={9} lg={8}>
        <input className={PriceCalStyles.surfaceTotal} type="text" value={surfaceTotal} disabled />
      </Col>
    </Row>

    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={4}>
        <SimpleText textKey="QuantityPriceCalculation_Subtotal" />
      </Col>
      <Col xs={9} lg={7}>
        <span className={PriceCalStyles.actualPrice}>{subTotal} </span><span className={PriceCalStyles.actualTooltip}><TooltipLabel messageTextKey={'QuantityPriceCalculation_Subtotal_Tooltip'} /></span>
      </Col>
    </Row>

    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
        <SanaButton
          textKey="AddToBasket"
          type="button"
          className={`${btnStyles.btnBig} ${styles.addToBasketBtn}`}
          placeholder={btnPlaceholder}
          onClick={addToBasket}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} lg={12} className={PriceCalStyles.wishListWrapper}>
        {showAddToWishList && <>{' '}<AddToWishlist product={product} /></>}
      </Col>
    </Row>

  </div>
  );

};

QuantityPriceCalculation.propTypes = {
  metersSquaredPerBox: number,
  culture: string,
  currencyInfo: object,
  addProducts: func,
  product: object,
  modifiedDate: oneOfType([ Date, string, object ]),
  updatedById: string,
  wishListEnabled: bool,
};

export default connect(
  ({ settings, localization, basket }) => ({
    allowUOMSelection: settings?.product?.allowUOMSelection,
    wishListEnabled: settings?.wishListEnabled,
    currencyInfo: settings?.currency,
    culture: localization?.currentLanguage?.cultureName,
    modifiedDate: basket?.modifiedDate,
    updatedById: basket?.updated?.updaterId,
    basketResult: basket?.basketResult,
  }),
  { addProducts },
)(QuantityPriceCalculation);

function generateQuantityModel(product, uomId) {
  const uom = product.uoms && product.uoms.find(u => u.id === uomId);
  return createQuantityModel(uom, false);
}