import { memo } from 'react';
import { Col, Row } from 'components/primitives/grid';
import styles from '../Details.module.scss';
import { RichText } from 'components/sanaText';

//3.3 UOM Calculation
const MetersSquaredPerBox = ({ metersSquaredPerBox }) => {

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={2}>
        <RichText textKey="MetersSquaredPerBox" />
      </Col>
      <Col xs={9} lg={10}>
        <span><strong> {metersSquaredPerBox}</strong></span>
      </Col>
    </Row>
    );

}

export default memo(MetersSquaredPerBox);