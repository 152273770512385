exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QuantityPriceCalculation_wrapper{background-color:#f2f2f2;padding:0 20px 20px}.QuantityPriceCalculation_wrapper .QuantityPriceCalculation_surfaceTotal{width:4em;height:2.15em;background:#fff;box-sizing:border-box;border-radius:3 px;outline:none;padding:0 .35 em;border:0 #c2c2c2;border-bottom:1 solid #c2c2c2;color:#333;text-align:center;margin:0 .4 em 2.65em}.QuantityPriceCalculation_wrapper .QuantityPriceCalculation_actual-price{display:block;font-size:2.8em;color:#000;line-height:normal;float:left;margin-right:3px}.QuantityPriceCalculation_wrapper .QuantityPriceCalculation_actual-tooltip{position:relative;top:11px}.QuantityPriceCalculation_wrapper .QuantityPriceCalculation_wish-list-wrapper{margin-top:10px}", ""]);

// exports
exports.locals = {
	"wrapper": "QuantityPriceCalculation_wrapper",
	"surfaceTotal": "QuantityPriceCalculation_surfaceTotal",
	"actual-price": "QuantityPriceCalculation_actual-price",
	"actualPrice": "QuantityPriceCalculation_actual-price",
	"actual-tooltip": "QuantityPriceCalculation_actual-tooltip",
	"actualTooltip": "QuantityPriceCalculation_actual-tooltip",
	"wish-list-wrapper": "QuantityPriceCalculation_wish-list-wrapper",
	"wishListWrapper": "QuantityPriceCalculation_wish-list-wrapper"
};