import { Col, Container, Row } from 'components/primitives/grid';
import { RichText, SimpleText } from 'components/sanaText';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../Details.module.scss';
import SampleLines from './SampleLines';
import { DangerAlert } from 'components/primitives/alerts';


//3.4.Sample ordering
const Locations = ({ basketResult }) => {

  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {

    if (basketResult)
      setErrorMessage(basketResult.message);

  }, [basketResult]);

  return (

    <Container>
      <Row className={`${styles.displayMdBlock} clearfix`}>

        <Row className={styles.row} crossAxisAlign="center">
          <Col xs={12} lg={12}>
            <h1><SimpleText textKey="SampleOrdering_LocationsHeader" /></h1>
            <p><RichText textKey="SampleOrdering_LocationsInfo" /></p>
            {errorMessage && <DangerAlert><span>{errorMessage}</span></DangerAlert>}
          </Col>
        </Row>
        <Row className={styles.row} crossAxisAlign="center">
          <Col xs={12} lg={12} sm={12}>
            <SampleLines />
          </Col>
        </Row>
      </Row>
    </Container>

  );

}

export default connect(({ basket }) => ({
  basketResult: basket.basketResult,
}))(Locations);
