import { memo } from 'react';
import { Col, Row } from 'components/primitives/grid';
import styles from '../Details.module.scss';
import { RichText } from 'components/sanaText';
import { SimpleText } from '../../../sanaText';

//3.3 UOM Calculation
const OtherUomDescriptor = ({ metersSquaredPerBox }) => {

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={2}>
        <SimpleText textKey="CategoryUomText_Unit" />
      </Col>
      <Col xs={9} lg={10}>
        <span><strong> {metersSquaredPerBox}</strong></span>
      </Col>
    </Row>
    );

}

export default memo(OtherUomDescriptor);