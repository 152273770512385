import styles from '../product/Details.module.scss';
import { useProductContext } from '../product/hooks';
import { connect } from 'react-redux';
import Spinner from 'components/primitives/spinner/Spinner';
import { ProductThumbnail } from 'components/primitives/product';

//3.3.UOM Calculator
const ProductImage = ({ productSettings }) => {

  const { product, variantId } = useProductContext();
  if (!product) {
    return (<Spinner />);
  }

  const { hasVariants } = product;

  let image = product.image;
  const noImage = productSettings.noImage.medium;

  if (hasVariants && product.images && product.images.length) {
    if (variantId) {
      const variantImage = product.images.filter((img) => { return img.variantId === variantId });
      if (variantImage.length && variantImage[0].medium) {
        image = variantImage[0].medium;
      }
      else
        image = product.images[0].medium;
    }
    else
      image = product.images[0].medium;
  }

  return (
    <ProductThumbnail
      src={image}
      noImageSrc={noImage}
      title={product.title}
      className={styles.productImg}
      wrapperClassName={styles.productImgWrapper}
      placeholder={<Spinner />} />
  );
};

export default connect(
  ({ settings: { product: productSettings } }) => ({ productSettings }),
)(ProductImage);
